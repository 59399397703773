import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "container p-0" }
const _hoisted_3 = { class: "row pb-4" }
const _hoisted_4 = { class: "col-12 mb-4" }
const _hoisted_5 = { class: "r-font-family-heading r-font-size-500 r-font-weight-light my-4" }
const _hoisted_6 = { class: "r-font-size-400 mb-4" }
const _hoisted_7 = { class: "col-12" }
const _hoisted_8 = { class: "r-font-family-heading r-font-size-500 r-font-weight-light my-4" }
const _hoisted_9 = { class: "r-font-size-400 mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_r_button = _resolveComponent("r-button")!
  const _component_ModalComponent = _resolveComponent("ModalComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ModalComponent, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-12 mt-1 mb-3" }, [
              _createElementVNode("img", {
                alt: "Riverty logo",
                width: "143",
                src: "https://cdn.riverty.design/logo/riverty-logo-green.svg"
              })
            ], -1)),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.getTranslation("PopupOnboardTitle")), 1),
              _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.getTranslation("PopupOnboardDescription")), 1),
              _createVNode(_component_r_button, {
                variant: "primary",
                onClick: _ctx.handleGoOnboard
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTranslation("PopupOnboard")), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.getTranslation("PopupSettingsTitle")), 1),
              _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.getTranslation("PopupSettingsDescription")), 1),
              _createVNode(_component_r_button, {
                variant: "secondary",
                onClick: _ctx.handleGoSettings
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTranslation("PopupSettings")), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ])
        ])
      ]),
      _: 1
    })
  ]))
}